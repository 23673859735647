var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            title: _vm.$t("kanban.title"),
            "footer-class": "footerClass",
            "content-class": "shadow",
            "no-close-on-backdrop": "",
            scrollable: "",
          },
          on: { hidden: _vm.modalCancel },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({}) {
                return [
                  _c(
                    "b-button",
                    {
                      staticStyle: { "margin-right": "auto" },
                      attrs: { size: "sm", variant: "secondary" },
                      on: { click: _vm.resetDefaults },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.defaults")))]
                  ),
                  [
                    _c(
                      "b-button",
                      {
                        attrs: { size: "sm", variant: "success" },
                        on: { click: _vm.ok },
                      },
                      [_vm._v(_vm._s(_vm.$t("button.ok")))]
                    ),
                    _c(
                      "b-button",
                      {
                        attrs: { size: "sm", variant: "danger" },
                        on: { click: _vm.cancel },
                      },
                      [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                    ),
                  ],
                ]
              },
            },
          ]),
          model: {
            value: _vm.modalShow,
            callback: function ($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow",
          },
        },
        [
          _c(
            "b-form-group",
            { attrs: { label: _vm.$t("kanban.name"), "label-for": "name" } },
            [
              _c("b-form-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: { required: true },
                    expression: "{ required: true }",
                  },
                ],
                attrs: {
                  id: "name",
                  "data-vv-as": _vm.$t("kanban.name"),
                  "data-vv-name": "kanban.name",
                  "data-vv-delay": "500",
                },
                model: {
                  value: _vm.profileData.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.profileData, "name", $$v)
                  },
                  expression: "profileData.name",
                },
              }),
              _c(
                "b-form-invalid-feedback",
                {
                  staticClass: "alert-danger form-field-alert",
                  class: { "d-block": _vm.showNameError },
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["far", "circle-exclamation"] },
                  }),
                  _vm._v("  " + _vm._s(_vm.errors.first("kanban.name")) + " "),
                ],
                1
              ),
            ],
            1
          ),
          _vm._l(_vm.columnData, function (column, index) {
            return _c(
              "div",
              { key: index, staticClass: "kanban-stage-container" },
              [
                _c("div", { staticClass: "kanban-stage-name" }, [
                  _vm._v("Stage: "),
                  _c("b", [_vm._v(_vm._s(column.name))]),
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-sm" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.$t("kanban.color"),
                            for: "color" + index,
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              type: "color",
                              id: "color" + index,
                              "data-vv-as": _vm.$t("kanban.color"),
                              "data-vv-name": "kanban.color",
                              "data-vv-delay": "500",
                            },
                            model: {
                              value: column.color,
                              callback: function ($$v) {
                                _vm.$set(column, "color", $$v)
                              },
                              expression: "column.color",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-sm" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.$t("kanban.limit"),
                            for: "limit" + index,
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c(
                                "b-input-group-prepend",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.limitAdd(index, -1)
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "minus"] },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("b-form-input", {
                                attrs: {
                                  id: "limit" + index,
                                  "data-vv-name": "kanban.limit",
                                  "data-vv-delay": "500",
                                  placeholder: _vm.$t(
                                    "kanban.placeholder.limit"
                                  ),
                                  type: "text",
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.limitFormat(column)
                                  },
                                },
                                model: {
                                  value: column.limit,
                                  callback: function ($$v) {
                                    _vm.$set(column, "limit", $$v)
                                  },
                                  expression: "column.limit",
                                },
                              }),
                              _c(
                                "b-input-group-append",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.limitAdd(index, 1)
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "plus"] },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-sm" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.$t("kanban.progress"),
                            for: "progress" + index,
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c(
                                "b-input-group-prepend",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.progressAdd(column, -1)
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "minus"] },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("b-form-input", {
                                attrs: {
                                  id: "progress" + index,
                                  "data-vv-name": "kanban.progress",
                                  "data-vv-delay": "500",
                                  placeholder: _vm.$t(
                                    "kanban.placeholder.progress"
                                  ),
                                  type: "text",
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.progressFormat(column)
                                  },
                                },
                                model: {
                                  value: column.progress,
                                  callback: function ($$v) {
                                    _vm.$set(column, "progress", $$v)
                                  },
                                  expression: "column.progress",
                                },
                              }),
                              _c(
                                "b-input-group-append",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.progressAdd(column, 1)
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "plus"] },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-sm" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.isStageVisible(column),
                            for: "visible" + index,
                          },
                        },
                        [
                          _c("b-form-checkbox", {
                            attrs: {
                              switch: "",
                              id: "visible" + index,
                              "data-vv-name": "kanban.visible",
                              "data-vv-delay": "500",
                            },
                            model: {
                              value: column.visible,
                              callback: function ($$v) {
                                _vm.$set(column, "visible", $$v)
                              },
                              expression: "column.visible",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }